import { connect } from 'react-redux';
import { updateFavourite } from '@noths/polaris-client-user-favourites';
import getConfig from 'next/config';

import { ProductList } from 'src/components/organisms/ProductList/components/ProductList';
import { productListClick } from 'src/components/organisms/ProductList/modules/actions';
import { selectReferredBy } from 'src/components/organisms/ProductList/modules/selectors';
import type {
  ProductListContainerDispatchProps,
  ProductListContainerStateProps,
} from 'src/components/organisms/ProductList/types';
import type { ReduxApplicationState } from 'src/redux/combinedReducer';
import { selectProviderAttributionToken } from 'src/redux/products/selectors';
import type { UpdateHistoryPayload } from 'src/redux/products/thunks/updatePageHistory';
import { updatePageHistory } from 'src/redux/products/thunks/updatePageHistory';
import type { AppThunkDispatch } from 'src/redux/store';
import { actions as visibilityTrackingActions } from 'src/redux/visibilityTracking/slice';
import type { NextConfig } from 'src/types/nextConfig';

const nextConfig = getConfig() as NextConfig;

export const mapStateToProps = (state: ReduxApplicationState): ProductListContainerStateProps => {
  return {
    referredBy: selectReferredBy(state),
    providerAttributionToken: selectProviderAttributionToken(state),
  };
};

export const mapDispatchToProps = (
  dispatch: AppThunkDispatch,
): ProductListContainerDispatchProps => {
  return {
    updateFavourite: (productCode: number) =>
      dispatch(updateFavourite({ productCode, nextConfig })),
    updatePageHistory: (payload: UpdateHistoryPayload) => dispatch(updatePageHistory(payload)),
    onProductVisible: (productId: number) =>
      dispatch(visibilityTrackingActions.onProductVisible({ productId })),
    onProductClick: ({
      destinationUrl,
      linkOpensInSameWindow,
      productCode,
      productUrl,
    }: {
      destinationUrl: string;
      linkOpensInSameWindow: boolean;
      productCode: number;
      productUrl: string;
    }) => {
      dispatch(visibilityTrackingActions.sendProductImpressionBatch());
      dispatch(
        productListClick({ productUrl, destinationUrl, productCode, linkOpensInSameWindow }),
      );
    },
  };
};

export const ProductListContainer = connect(mapStateToProps, mapDispatchToProps)(ProductList);
