import React, { useEffect, useState } from 'react';
import {
  Breakpoint,
  getBreakpoints,
  isBreakpointSmallOrBelow,
  useBreakpoint,
} from '@noths/polaris-client-ribbons-base';
import type { DrawerProps } from '@noths/polaris-client-ribbons-design-system';
import { Drawer, Responsive } from '@noths/polaris-client-ribbons-design-system';
import dynamic from 'next/dynamic';

import { ModalLoader } from 'src/components/molecules/ModalLoader/ModalLoader';
import { FilterListContainer } from 'src/components/organisms/Filter/containers/FilterListContainer';
import * as styles from 'src/components/organisms/Filter/styles/Filter.styles';

const FilterModal = dynamic(
  async () => (await import(/* webpackChunkName: "FilterModal" */ './FilterModal')).FilterModal,
  { loading: () => <ModalLoader /> },
);

const breakpointsWithModal = getBreakpoints({ maxBreakpoint: Breakpoint.M });

export type FilterProps = {
  closeFilterModal: () => void;
  isModalOpen: boolean;
};

export const Filter = ({ closeFilterModal, isModalOpen }: FilterProps) => {
  const breakpoint = useBreakpoint();
  const isMobile = isBreakpointSmallOrBelow(breakpoint);
  const [drawerSize, setDrawerSize] = useState<DrawerProps['panelSize']>('full');
  const [transitionFrom, setTransitionFrom] = useState<DrawerProps['transitionFrom']>('bottom');

  const handleModalClose = () => {
    closeFilterModal();
  };

  useEffect(() => {
    if (!breakpointsWithModal.includes(breakpoint)) {
      closeFilterModal();
    }

    setDrawerSize(isMobile ? 'full' : 'partial');
    setTransitionFrom(isMobile ? 'bottom' : 'left');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakpoint]);

  return (
    <div css={styles.filterWrapper}>
      <Responsive minBreakpoint={Breakpoint.L}>
        <FilterListContainer removeSortFilter />
      </Responsive>
      <Drawer
        contentContainerStyles={styles.drawerContentContainer}
        contentLabel="Product filtering options"
        isOpen={isModalOpen}
        onOverlayClick={handleModalClose}
        onRequestClose={handleModalClose}
        panelSize={drawerSize}
        transitionFrom={transitionFrom}
        useScopedStyles={true}
      >
        <FilterModal />
      </Drawer>
    </div>
  );
};
