import React from 'react';
import { Breakpoint, useBreakpoint } from '@noths/polaris-client-ribbons-base';

import { RadioButtonGroup } from 'src/components/molecules/RadioButtonGroup/RadioButtonGroup';
import type { ChangedFilterDetails } from 'src/components/organisms/Filter/types';
import type { BrowseDataAPIFilter } from 'src/services/browse-data-api/types/BrowseDataAPIFilter';
import type { ExpandableContentTogglePayload } from './FilterOptionsExpandableContent';
import { FilterOptionsExpandableContent } from './FilterOptionsExpandableContent';
import { getActiveFilterCountString } from './utils';

interface DeliveryZoneFilterProps {
  filter: BrowseDataAPIFilter;
  onExpandableContentToggle: (payload: ExpandableContentTogglePayload) => void;
  onFilterOptionChange: (filterOptionDetails: ChangedFilterDetails) => void;
}

export const onChange = (
  radioButtonValue: string,
  filter: BrowseDataAPIFilter,
  onFilterOptionChange: (filterOptionDetails: ChangedFilterDetails) => void,
) => {
  const option = filter.options.find(({ value }) => value === radioButtonValue);

  onFilterOptionChange({
    uid: filter.uid,
    title: filter.title,
    options: [
      {
        active: !option?.active,
        title: option?.title,
        value: radioButtonValue,
      },
    ],
  });
};

export const DeliveryZoneFilter = ({
  filter,
  onExpandableContentToggle,
  onFilterOptionChange,
}: DeliveryZoneFilterProps) => {
  const breakpoint = useBreakpoint();
  const isExpandable = filter.options.length > 5 && breakpoint !== Breakpoint.XXS;
  const activeOptionIndex = filter.options.findIndex(({ active }) => active === true);
  const checkedButtonIndex = activeOptionIndex >= 0 ? activeOptionIndex : filter.defaultOption;

  return (
    <FilterOptionsExpandableContent
      filter={filter}
      isExpandable={isExpandable}
      onExpandableContentToggle={onExpandableContentToggle}
    >
      <RadioButtonGroup
        accessibleLabel={filter.title}
        hideLabel
        name={filter.uid}
        onChange={(radioButtonValue: string) =>
          onChange(radioButtonValue, filter, onFilterOptionChange)
        }
        radioButtonData={filter.options.map(({ activeCount, title, value }, index) => ({
          checked: index === checkedButtonIndex,
          labelText: title,
          value: value as string,
          secondaryLabelText: getActiveFilterCountString(activeCount),
        }))}
      />
    </FilterOptionsExpandableContent>
  );
};
