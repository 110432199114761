/* istanbul ignore file */

import { maxBreakpoints, minBreakpoints, theme } from '@noths/polaris-client-ribbons-base';
import {
  LINK_CAROUSEL__CONTAINER_CLASS,
  LINK_CAROUSEL__HEADING_CLASS,
} from '@noths/polaris-client-ribbons-design-system';
import { mediaQuery, pxToRem } from '@noths/polaris-client-styles';
import { css } from 'styled-components';

const { spacing } = theme;

export const wrapper = css`
  margin: ${pxToRem(spacing.md)} 0 ${pxToRem(100)};
  ${mediaQuery(
    {
      min: minBreakpoints.L,
    },
    css`
      margin: ${pxToRem(spacing.xxl)} 0 ${pxToRem(spacing.xxxl)};
    `,
  )}
`;

export const productListSidebar = css`
  grid-column: 2 / span 2;
  ${mediaQuery(
    { max: maxBreakpoints.M },
    css`
      display: none;
    `,
  )};
`;

export const productListWrapper = css`
  ${mediaQuery(
    { max: maxBreakpoints.S },
    css`
      padding: 0;
    `,
  )};
`;

export const breadcrumbContainer = css`
  grid-column: 1 / -1;
  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 2 / -2;
    `,
  )}
`;

export const quickFiltersWrapper = css`
  ${
    /* Fixes iOS 14 Chrome/Safari rendering issues.
     See https://github.com/notonthehighstreet/polaris/pull/1674 for explanation.
  */ ''
  }
  transform: translate3d(0, 0, 0);

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      display: none;
    `,
  )}
`;

export const relatedCategories = css`
  margin-top: ${pxToRem(spacing.xxxl)};
  padding: ${pxToRem(spacing.sm)} 0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-color: var(--neutral-black-30);

  ${mediaQuery(
    { max: maxBreakpoints.S },
    css`
      .${LINK_CAROUSEL__HEADING_CLASS} {
        padding-left: ${pxToRem(spacing.md)};
      }
    `,
  )}

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      .${LINK_CAROUSEL__CONTAINER_CLASS} {
        max-width: 45rem;
        margin: 0 auto;
      }
    `,
  )}
`;

export const productPagesWrapper = css`
  position: relative;
  grid-column: span 12;

  ${mediaQuery(
    { min: minBreakpoints.L },
    css`
      grid-column: 4 / span 8;
    `,
  )}
`;
