import React from 'react';
import { Breakpoint, useBreakpoint } from '@noths/polaris-client-ribbons-base';

import { CheckboxGroup } from 'src/components/molecules/CheckboxGroup/CheckboxGroup';
import type { ChangedFilterDetails } from 'src/components/organisms/Filter/types';
import type { BrowseDataAPIFilter } from 'src/services/browse-data-api/types/BrowseDataAPIFilter';
import type { ExpandableContentTogglePayload } from './FilterOptionsExpandableContent';
import { FilterOptionsExpandableContent } from './FilterOptionsExpandableContent';
import { getActiveFilterCountString } from './utils';

interface CheckboxFilterProps {
  filter: BrowseDataAPIFilter;
  isExpanded?: boolean;
  onExpandableContentToggle: (payload: ExpandableContentTogglePayload) => void;
  onFilterOptionChange: (filterOptionDetails: ChangedFilterDetails) => void;
}

const onChange = (
  ev: React.ChangeEvent<HTMLInputElement>,
  filter: BrowseDataAPIFilter,
  onFilterOptionChange: (filterOptionDetails: ChangedFilterDetails) => void,
) => {
  const changedCheckboxValue = ev.target.value;
  const option = filter.options.find((option) => option.value === changedCheckboxValue);

  onFilterOptionChange({
    uid: filter.uid,
    title: filter.title,
    options: [
      {
        active: !option?.active,
        title: option?.title,
        value: changedCheckboxValue,
      },
    ],
  });
};

export const CheckboxFilter = ({
  filter,
  isExpanded = true,
  onExpandableContentToggle,
  onFilterOptionChange,
}: CheckboxFilterProps) => {
  const breakpoint = useBreakpoint();
  const isExpandable = filter.options.length > 5 && breakpoint !== Breakpoint.XXS;

  return (
    <FilterOptionsExpandableContent
      filter={filter}
      isExpandable={isExpandable && isExpanded}
      onExpandableContentToggle={onExpandableContentToggle}
    >
      <CheckboxGroup
        accessibleLabel={filter.title}
        checkboxData={filter.options.map(({ active, activeCount, title, value }) => ({
          checked: active,
          labelText: title,
          value: value as string,
          secondaryLabelText: getActiveFilterCountString(activeCount),
        }))}
        hideLabel
        name={filter.uid}
        onChange={(e) => onChange(e, filter, onFilterOptionChange)}
      />
    </FilterOptionsExpandableContent>
  );
};
